import { ReactNode } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import clsx from 'clsx'
import { MEDIA, useMedia } from 'common'
import {
	AbstractButton,
	Button,
	ButtonProps,
	NativeProps,
	StickyPanel,
} from 'ui'
import { PanelProvider, usePanel } from './usePanel'
import Modal from '../Modal'

interface PanelButton extends Omit<ButtonProps, 'onClick'> {
	buttonText: string
	children: ReactNode
	panelShow?: 'all' | 'only-mobile'
	panelTitle?: string
	secondaryButton?: ReactNode
}

function PanelButton({
	buttonText,
	panelTitle,
	children,
	className,
	secondaryButton,
	panelShow = 'all',
	...props
}: PanelButton) {
	const { isOpened, toggleIsOpened } = usePanel()

	const isMobile = useMedia(MEDIA.mobile, panelShow === 'only-mobile')

	if (!isMobile) {
		return <>{children}</>
	}

	return (
		<>
			<StickyPanel className="flex justify-center">
				<motion.div
					className="flex justify-center gap-2 pb-2"
					initial={{
						y: 100,
					}}
					animate={{
						y: 0,
						transition: {
							delay: 1,
						},
					}}
				>
					{secondaryButton}
					<Button
						theme="colored"
						className={clsx('shadow-elevation', className)}
						onClick={() => toggleIsOpened(true)}
						{...props}
					>
						{buttonText}
					</Button>
				</motion.div>
			</StickyPanel>
			<AnimatePresence>
				{isOpened && (
					<Modal
						title={panelTitle || buttonText}
						onClose={() => toggleIsOpened(false)}
					>
						<Modal.Body>{children}</Modal.Body>
					</Modal>
				)}
			</AnimatePresence>
		</>
	)
}

function ToggleAbstractButton({ onClick, ...props }: NativeProps) {
	const { toggleIsOpened } = usePanel()

	return (
		<AbstractButton
			onClick={(event) => {
				toggleIsOpened()

				if (onClick) {
					onClick(event as any)
				}
			}}
			{...props}
		/>
	)
}

function ToggleButton({ onClick, ...props }: ButtonProps) {
	const { toggleIsOpened } = usePanel()

	return (
		<Button
			onClick={(event) => {
				toggleIsOpened()

				if (onClick) {
					onClick(event as any)
				}
			}}
			{...props}
		/>
	)
}

WithProvider.ToggleAbstractButton = ToggleAbstractButton
WithProvider.ToggleButton = ToggleButton

export default function WithProvider(props: PanelButton) {
	return (
		<PanelProvider>
			<PanelButton {...props} />
		</PanelProvider>
	)
}
