import {
	ComponentProps,
	ReactNode,
	RefObject,
	useEffect,
	useState,
} from 'react'
import { createPortal } from 'react-dom'
import { Variant, motion, useMotionValue, useScroll } from 'framer-motion'
import { useRouter } from 'next/compat/router'
import { isBrowser, useDevice, useIsomorphicLayoutEffect } from 'common'
import clsx from 'clsx'
import { ClientOnly, Noop } from 'ui'

type StickyPanelProps = {
	children: ReactNode
	container?: RefObject<HTMLElement>
	position?: 'bottom' | 'top'
	threshold?: number
	enabled?: boolean
} & ComponentProps<'div'>

const StickyPanel = ({
	children,
	container,
	className,
	enabled = true,
	threshold = 0,
	position: _position = 'bottom',
}: StickyPanelProps) => {
	const isBottom = _position === 'bottom'

	const variants: Record<'show' | 'hide', Variant> = {
		show: {
			y: isBottom ? 40 : -40,
		},
		hide: {
			y: isBottom ? '100%' : '-100%',
		},
	}

	const initialVariant = threshold ? 'hide' : 'show'

	const [variant, setVariant] = useState(initialVariant)

	const device = useDevice()

	const position = useMotionValue<'fixed' | 'absolute'>('fixed')

	const bottom = useMotionValue<number | 'unset'>(isBottom ? 0 : 'unset')
	const top = useMotionValue<number | 'unset'>(isBottom ? 'unset' : 0)

	const { scrollY } = useScroll()

	const { asPath } = useRouter() ?? {}

	useIsomorphicLayoutEffect(() => {
		const main = container?.current ?? document.getElementById('main')

		if (!main) {
			return
		}

		const isBefore = !isBottom && scrollY.get() <= 88
		const isAfter = main.clientHeight - window.innerHeight <= 0

		const isWithin = !isAfter && !isBefore

		if (isWithin) {
			position.set('fixed')
		} else {
			position.set('absolute')
		}
	}, [position, asPath, isBottom, container, threshold])

	useEffect(() => {
		// Hacky way to have dynamic position of Help Desk widget
		const timeout = setTimeout(() => {
			const panelHolderElement = document.getElementById('panel')
			const helpDeskElement = document.querySelector(
				'.js-help-desk'
			) as HTMLElement

			if (!panelHolderElement || !helpDeskElement) {
				return
			}

			const childElements = Array.from(panelHolderElement.children)

			let height = 0

			childElements.forEach((element) => {
				if (element.classList.contains('js-help-desk')) {
					return
				}

				const child = element.firstElementChild

				height = child?.clientHeight || 0
			})

			// Panel height - HelpDesk margin bottom + spacing between HelpDesk and Panel
			const offset = height - 16 + 8

			helpDeskElement.style.marginBottom = Math.max(offset, 0) + 'px'
		}, 100)

		return () => {
			clearTimeout(timeout)
		}
	}, [asPath])

	useEffect(() => {
		const main = container?.current ?? document.getElementById('main')

		if (!main) {
			return
		}

		const headerHeight = document.querySelector('.js-header')?.clientHeight ?? 0

		const handleBottom = (y: number) => {
			const bottom = main.clientHeight - window.innerHeight + headerHeight

			if (y >= bottom) {
				position.set('absolute')
			} else {
				position.set('fixed')
			}

			if (threshold) {
				const shouldShow = y >= threshold
				const variant = shouldShow ? 'show' : 'hide'

				setVariant(variant)
			}
		}

		const handleTop = (y: number) => {
			const isBefore = y <= main.offsetTop + headerHeight
			const isAfter = y >= main.clientHeight

			const isWithin = !isBefore && !isAfter

			if (isWithin) {
				position.set('fixed')
				top.set(0)
				bottom.set('unset')

				return
			}

			position.set('absolute')

			if (isBefore) {
				top.set(0)
				bottom.set('unset')
			}

			if (isAfter) {
				top.set('unset')
				bottom.set(device === 'desktop' ? -192 : -152)
			}
		}

		const unsubscribe = scrollY.on('change', (y) => {
			if (isBottom) {
				handleBottom(y)
			} else {
				handleTop(y)
			}
		})

		return unsubscribe
	}, [position, threshold, scrollY, isBottom, container, top, bottom, device])

	if (!enabled || !isBrowser) {
		return <>{children}</>
	}

	const placeholderElement =
		container?.current ?? document.getElementById('panel')

	if (!placeholderElement) {
		return <Noop />
	}

	const panel = (
		<motion.div
			variants={variants}
			animate={variant}
			initial={threshold ? 'hide' : 'show'}
			className={clsx(
				'z-panel left-0 right-0 will-change-transform',
				isBottom ? 'pb-10' : 'pt-10',
				className
			)}
			style={{
				position,
				top,
				bottom,
			}}
		>
			{children}
		</motion.div>
	)

	return createPortal(panel, placeholderElement)
}

export function WithClientOnly(props: StickyPanelProps) {
	return (
		<ClientOnly>
			<StickyPanel {...props} />
		</ClientOnly>
	)
}
