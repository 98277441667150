import { useState } from 'react'
import { createStore } from 'common'

export const [PanelProvider, usePanel] = createStore('PanelStore', () => {
	const [isOpened, setIsOpened] = useState(false)

	const toggleIsOpened = (state?: boolean) => {
		setIsOpened((previousState) => {
			if (typeof state !== 'undefined') {
				return state
			}

			return !previousState
		})
	}

	return {
		isOpened,
		toggleIsOpened,
	}
})
