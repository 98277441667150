const NUMERALS = [
	'I',
	'II',
	'III',
	'IV',
	'V',
	'VI',
	'VII',
	'VIII',
	'IX',
	'X',
	'XI',
	'XII',
	'XIII',
	'XIV',
	'XV',
]

export const addRomanNumeralToText = (text: string, idx: number) => {
	const numIdx = idx - 1
	const roman = NUMERALS[numIdx]

	if (!roman) {
		return text
	}

	return roman.concat('. ', text)
}
