import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { getCookie } from 'cookies-next'

const PRIVACY_PAGES = ['/privacy', '/privacy-policy', '/datenschutz']

export function useReloadPrivacyPage() {
	const { asPath, replace, reload } = useRouter()

	useEffect(() => {
		if (!PRIVACY_PAGES.some((page) => asPath.includes(page))) {
			return
		}

		const element = document.getElementById('ot-sdk-cookie-policy')

		if (!element) {
			return
		}

		const hasCookie = !!getCookie('OptanonAlertBoxClosed')
		const hasChildren = !!element.childNodes.length
		const hasReloaded = asPath.includes('reloaded=true')

		if (hasCookie && !hasChildren && !hasReloaded) {
			replace(`${asPath}?reloaded=true`, undefined, { shallow: true }).then(
				reload
			)
		}
	}, [asPath, replace, reload])
}
