import { ComponentPropsWithoutRef } from 'react'

export function Sheet(props: ComponentPropsWithoutRef<'svg'>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="currentColor"
				d="m17.416 6.918-2.333-2.332A2.009 2.009 0 0 0 13.67 4H8a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8c1.1 0 2-.9 2-2V8.331c0-.528-.21-1.037-.584-1.413ZM16.472 18c0 .276-.196.5-.472.5H8a.5.5 0 0 1-.5-.5V6.004a.5.5 0 0 1 .5-.5h5V8a1 1 0 0 0 1 1h2.472v9ZM9 12.75c0 .416.338.75.75.75h4.5c.416 0 .75-.334.75-.75a.748.748 0 0 0-.75-.75h-4.5a.752.752 0 0 0-.75.75ZM14.25 15h-4.5a.751.751 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5Z"
			/>
		</svg>
	)
}
