import { useReducer } from 'react'
import { z } from 'zod'
import clsx from 'clsx'
import RichTextResolverService from 'service/RichTextResolverService'
import { AnimatePresence } from 'framer-motion'
import { Noop, ArrowRight, OptimizedImage, AbstractButton, Modal } from 'ui'
import { SmallCardSchema } from './SmallCard.schema'

type CardColor = z.TypeOf<typeof SmallCardSchema>['color']

type SmallCardProps = {
	blok: z.TypeOf<typeof SmallCardSchema>
}

const SmallCard = (props: SmallCardProps) => {
	const {
		title,
		image,
		button_text,
		button_url,
		image_multiply_effect,
		color = 'red',
	} = props.blok
	const [showModal, toggleShowModal] = useReducer((state) => !state, false)

	const colorStyleMap = new Map<CardColor, string>([
		['red', 'bg-primary'],
		['blue', 'bg-accent-blue'],
		['gray', 'bg-gray-10'],
		['beige', 'bg-accent-beige'],
		['white', 'bg-white'],
	])

	const renderSubtitle = () => {
		if (!props.blok.subtitle) {
			return <Noop />
		}

		return new RichTextResolverService(props.blok.subtitle).resolveRichText()
	}

	const cardContent = () => {
		return (
			<>
				<h2 className="subtitle_highlight word_break mb-2 line-clamp-3 normal-case">
					{title}
				</h2>
				{props.blok.subtitle && (
					<div className="word_break line-clamp-4 [&_*]:inline">
						{renderSubtitle()}
					</div>
				)}
			</>
		)
	}

	return (
		<>
			<AnimatePresence>
				{showModal && (
					<Modal onClose={toggleShowModal}>
						<Modal.Body>
							<h3 className="title_large mb-2">{title}</h3>
							{renderSubtitle()}
							{image?.filename && (
								<div className="relative h-[400px] w-full sm:h-[250px]">
									<OptimizedImage
										src={image.filename}
										alt={title}
										className={clsx(
											'object-contain object-top',
											image_multiply_effect && 'mix-blend-multiply'
										)}
										fill
										sizes="(max-width: 819px) 100vw, 50vw"
									/>
								</div>
							)}
						</Modal.Body>
					</Modal>
				)}
			</AnimatePresence>
			<div
				className={clsx(
					'relative flex h-full flex-col overflow-hidden rounded-sm',
					image?.filename ? 'min-h-[428px]' : 'min-h-[318px]',
					colorStyleMap.get(color),
					(color === 'red' || color === 'blue') && 'text-white'
				)}
			>
				<div className="relative grow p-8">{cardContent()}</div>

				{image?.filename && (
					<div className="relative h-[300px]">
						<OptimizedImage
							src={image.filename}
							alt={title}
							className={clsx(
								'object-contain object-top',
								image_multiply_effect && 'mix-blend-multiply'
							)}
							fill
							sizes="(max-width: 819px) 100vw, 50vw"
						/>
					</div>
				)}
				<AbstractButton
					{...(button_url
						? { href: button_url }
						: { onClick: toggleShowModal, 'aria-label': 'Open modal' })}
					className="absolute bottom-7 right-8 flex items-center"
				>
					{button_text && (
						<p className="p_highlight mr-3 truncate">{button_text}</p>
					)}
					<ArrowRight />
				</AbstractButton>
			</div>
		</>
	)
}

export default SmallCard
