import { z } from 'zod'
import { ImageOptionalSchema, RichTextOptionalSchema } from 'schema'

export const SmallCardSchema = z.object({
	title: z.string().trim().min(1),
	subtitle: RichTextOptionalSchema,
	color: z.enum(['red', 'blue', 'gray', 'beige', 'white']),
	image: ImageOptionalSchema,
	button_text: z.string().optional(),
	button_url: z.string().optional(),
	image_multiply_effect: z.boolean(),
})
