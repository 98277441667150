import { useCallback, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { SideMenuLink } from 'service'

export const useNestablePageNavigation = (
	currentPageOrder: number,
	sideMenuLinks: Array<SideMenuLink>
) => {
	const { t } = useTranslation()

	const findClosestItems = useCallback(() => {
		const orderedLinks = sideMenuLinks.filter((link) => link.order)

		const next =
			orderedLinks.find((link) => {
				const numberedOrder = Number(link.order)

				return numberedOrder > currentPageOrder
			}) || null

		const previous =
			[...orderedLinks]
				.reverse()
				.find((link) => Number(link.order) < currentPageOrder) || null

		return { next, previous }
	}, [currentPageOrder, sideMenuLinks])

	const { previousStep, nextStep } = useMemo(() => {
		const [firstMenuLink] = sideMenuLinks

		if (!firstMenuLink) {
			return {
				previousStep: null,
				nextStep: null,
			}
		}

		let previousStep = {
			slug: firstMenuLink.fullSlug,
			label: t('Back to main page'),
		}
		let nextStep = previousStep

		const { previous, next } = findClosestItems()

		if (sideMenuLinks.length === 2) {
			return { previousStep, nextStep }
		}

		if (previous) {
			previousStep = {
				slug: previous.fullSlug,
				label: t('Previous'),
			}
		}

		if (next) {
			nextStep = {
				slug: next.fullSlug,
				label: t('Next'),
			}
		}

		return { previousStep, nextStep }
	}, [findClosestItems, sideMenuLinks, t])

	return { previousStep, nextStep }
}
